import bg_0 from "./bg_0.jpg";
import bg_1 from "./bg_1.jpg";
import bg_2 from "./bg_2.jpg";
import bg_3 from "./bg_3.jpg";
import bg_4 from "./bg_4.jpg";
import bg_5 from "./bg_5.jpg";

const Bgs = [bg_0,bg_1,bg_2,bg_3,bg_4,bg_5];

export default Bgs;

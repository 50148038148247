import { faGithub,faYoutube} from '@fortawesome/free-brands-svg-icons'
import { faCamera,faBolt} from '@fortawesome/free-solid-svg-icons'

const SegmentContent = [
  {
    text:"",
    text_small:"",
    icon: null,
    goto: "",
    goto_link: ""
  },
  {
    text:"Hello, my name is Markus",
    text_small:"You are currently roaming my portfolio. This is a place for my projects and my work. Feel welcome.",
    icon:null,
    goto:"",
    goto_link: ""
  },
  {
    text:"If you are looking for my coding projects,go and vist my GitHub page ...",
    text_small:"where I focus my work on drawing engines mostly in C and C++. I am also interested in web developement, mainly in PHP, JS, CSS and some HTML. My upcoming project will also feature React.js as the framework.",
    icon:faGithub,
    goto:"GitHub",
    goto_link: "https://github.com/inimodo"
  },
  {
    text:"For my camera work i have to redirect you to the image viewer ...",
    text_small:"... where you can find all sort of pictues. But the most fun to capture are lightning ... if you are able to capture one.",
    icon:faCamera,
    goto:"my pictures",
    goto_link: "https://ini02.xyz/images"
  },
  {
    text:"And my electronic circuits can be found in my online collection ... ",
    text_small:"... of all the circuits that i have designed, tested and drawn. As i like to say: Electronic is magic that works",
    icon:faBolt,
    goto:"my circuits",
    goto_link: "https://ini02.xyz/circuits"
  },
  {
    text:"But if your are in search for my socialmedia ...",
    text_small:"... i only can direct you to my youtube channel, where i post Firework videos of my journey as a pyrotechnician. ",
    icon:faYoutube,
    goto:"my YouTube Channel",
    goto_link: "https://www.youtube.com/@ini_pyrotech"
  }
];
export default SegmentContent;

import React from 'react';
import Header from './elements/header/header.js';
import './css/global.css';
import './css/font/src/font.css';

class INI extends React.Component{
  constructor(props)
  {
    super(props);
    this.state={
      };
  }
  render()
  {
    return (<Header/>);
  }
}
export default INI;

import React from 'react';
import '../../css/header.css';


import { useState, useEffect } from "react";

import Segment from './segment.js';
import SegmentContent from './segment_content.js';

//https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

//https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

function Header(props)
{
  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  const { height } = useWindowDimensions();
  const [scrollY, setScrollY] = useState(0);
  function logit() {
    setScrollY(window.pageYOffset);
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);


  const h_scale = 1;

  var index = Math.floor(scrollY*h_scale/height);
  var index_offset = Math.floor((height*0.5+(scrollY*h_scale))/height);
  var scroll = scrollY*h_scale/height;
  var rel_scroll = scroll-index;
  var cen_scroll = rel_scroll;

  if(rel_scroll<0.5)
  {
    cen_scroll = 1.0-rel_scroll;
  }
  cen_scroll = (cen_scroll-0.5)*2;

  var sin_scale = Math.cos((cen_scroll-1)*1.5);


  var pagedata = {
    "index_offset":index_offset,
    "scroll":scroll,
    "sin_scale":sin_scale,
    "rel_scroll":rel_scroll,
    "cen_scroll":cen_scroll,
    "height":height,
    "index":index

  };


  const segments = SegmentContent.map((segment,index)=>(
      <Segment
        pd={pagedata}
        index={index}
        segment={segment}
        key={index}
      />
    ));
  return(
    <div className="header">
    {segments}
    </div>
  );
}

export default Header;

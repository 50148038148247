import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Bgs from '../../bgs/bgs.js';
import Logo from '../../images/logo_clean.png';

function  Segment(props)
{
  const maxsize = 7;
  const overlap = 200;

  var scale = Math.cos((props.pd.cen_scroll-1)*1.5);
  var text_size = maxsize*scale;
  var text_op = scale;
  var bg_top = (props.pd.rel_scroll)*overlap;

  if(props.index !== props.pd.index)
  {
    bg_top = 0;
  }

  var bg_style = {
    backgroundImage: "url("+Bgs[props.index]+")",
    top:bg_top+"px"
  };

  var page_style = {
    height: props.pd.height
  };
  var text_style = {
    fontSize: text_size+"vh",
    filter: "opacity("+text_op+")",
  };
  var small_text_style = {
    fontSize: (text_size*0.5)+"vh",
    filter: "opacity("+text_op+")",
  };
  var link_text_style = {
    fontSize: (text_size*0.5)+"vh",
    filter: "opacity("+(text_op)+")",
  };
  var img_style = {
    scale: scale*100+"%",
    filter: "opacity("+text_op+")",
  };

  var logo;
  var text;
  if(props.index === 0)
  {
    logo = (<img alt="" style={img_style} src={Logo} className="logo"/>);
  }else
  {
    text = (
      <>
        <p style={text_style} className="text">{props.segment.text}</p>
      </>
    );
  }
//        <p style={small_text_style} className="text_small">{props.segment.text_small}</p>
  var link;
  if(props.segment.icon !== null && props.pd.cen_scroll > 0.5 )
  {
    link = (
      <a href={props.segment.goto_link} style={link_text_style} className="text_link "><FontAwesomeIcon style={link_text_style} icon={props.segment.icon} beat /> go to {props.segment.goto}</a>
    );
  }
  return (
    <div style={page_style} className="segment">
    {logo}
      <div className="background" style={bg_style}></div>
    {text}
    {link}
    </div>
  );
}

export default Segment;
